<template>
  <div class="app-login">
    <div id="banner" class="col-md-7 col-sd-12">
      <div>
        <img width="250px" src="@/assets/img/insideBrasil.png" alt="" />
      </div>
      <div>
        <video
          autoplay
          controls
          class="video-background"
          src="https://s3-sa-east-1.amazonaws.com/ilumeo-brasil-com-br/Inside_Brasil.mp4"
        ></video>
      </div>
    </div>
    <div class="mt-10 col-md-4 col-sd-12">
      <div class="heading">
        <span>Reenviar e-mail de confirmação</span>
      </div>
      <div class="form">
        <text-field
          id="email"
          label="E-mail"
          v-model="user.email"
          type="e-mail"
          placeholder="example@mail.com"
          :textFieldError="errorMail"
        />
        <custom-button
          color="primary"
          @click="sendEmail()"
          :loading="loading"
          text="Confirmar"
          :disabled="!isValidEmail"
        />
        <custom-button
          color="primary"
          @click="$router.push({ name: 'login' })"
          text="Voltar"
          outlined
        />
      </div>
    </div>
    <custom-modal v-model="show" @confirm="confirm" @cancel="cancel">
      <span slot="title">
        <span class="title text-center">Encontramos sua conta!</span>
      </span>
      <div slot="content" class="text-center">
        <span class="content-modal">
          Enviamos um e-mail para {{ user.email }} com as instruções para
          recuperar sua conta.
        </span>
      </div>
      <div slot="actions" class="d-flex text-center">
        <custom-button icon color="primary" @click="confirm" text="Confirmar"
          ><div slot="icon">
            <span class="material-icons material-icons-outlined">
              arrow_forward
            </span>
          </div></custom-button
        >
      </div>
    </custom-modal>
  </div>
</template>

<script>
import auth from "@/api/services/auth";
import CustomButton from "@/components/Button";
import TextField from "@/components/Form/TextField/";
import CustomModal from "@/components/Modal/";
import { validateEmail } from "@/utils/validators";
export default {
  components: {
    CustomButton,
    CustomModal,
    TextField,
  },
  data: () => ({
    user: {
      email: "",
    },
    loading: false,
    errorMail: null,
    show: false,
  }),
  computed: {
    isValidEmail() {
      let valid;
      if (validateEmail(this.user.email)) {
        valid = true;
      } else {
        valid = false;
      }
      return valid;
    },
  },
  methods: {
    async sendEmail() {
      this.loading = true;
      if (this.user.email && validateEmail(this.user.email)) {
        await auth.resendMail(this.user).then(
          (response) => {
            console.log(response);
            this.show = true;
            this.disabled = false;
            this.loading = false;
          },
          (error) => {
            this.disabled = false;
            this.loading = false;
            if (
              error.response.status === 401 ||
              error.response.status === 404
            ) {
              this.errorMail = "Usuário já foi confirmado";
            }
          }
        );
      } else {
        this.loading = false;
        this.errorMail = "Por favor, digite um e-mail válido.";
      }
    },
    confirm() {
      // some code...
      this.show = false;
      this.$router.push({ name: "login" });
    },
    cancel(close) {
      // some code...
      close();
    },
  },
};
</script>

<style scoped>
.app-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: #e7e7e7;
}
#banner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #221e1e;
  padding: 0px 25px;
  border: 0;
}
#login {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0d0c22;
}
.heading {
  margin-bottom: 10px;
  color: #0d0c22;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 1.2rem;
}

.video-background {
  width: 100%;
  height: 100%;
}
.form {
  display: flex;
  width: 95%;
  padding: 0px 0px;
  flex-direction: column;
}
.title {
  padding: 10px 0px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}
.footer-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 95%;
}
@media only screen and (max-width: 768px) {
  .app-login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
    background: #e7e7e7;
  }
  #banner {
    /* display: none; */
  }
  #login {
    padding: 2em 0.55em;
  }
}
</style>
